<template>
  <ion-page>
    <simple-header backHref="/b2b/account" :title="$t('accountPage.request_product')" />
    <ion-content fullscreen>
      <product-form @request-product="requestProduct" :key="key" />
    </ion-content>
    <!-- modal info -->
    <ion-modal
      :is-open="isOpenModalInfoRef"
      css-class="modal-info custom-modal"
      @didDismiss="setOpenModalInfo(false)"
    >
      <modal-content-info :title="modalInfoTitle" :content="modalInfoContent" @close-modal="closeModalInfo">
      </modal-content-info>
    </ion-modal>

    <!-- loading -->
    <ion-loading
      :is-open="isOpenLoadingref"
      @didDismiss="setOpenLoading(false)"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
    >
    </ion-loading>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import SimpleHeader from '@/modules/b2b/views/shared/SimpleHeader.vue';
import ProductForm from './ProductForm.vue';
import ModalContentInfo from '@/modules/b2b/views/shared/ModalContentInfo.vue';

import { createNamespacedHelpers } from 'vuex';
import { ACTIONS } from '@/modules/b2b/store/account/actions';
import { useRouter } from 'vue-router';

const { mapActions, mapGetters } = createNamespacedHelpers('b2b/account');

export default defineComponent({
  name: 'RequestProduct',
  components: {
    // User Defined component
    SimpleHeader,
    ProductForm,
    ModalContentInfo
  },
  data() {
    return {
      user: null,
      selectedCompany: null,
      key: 0,

      titleSuccess: this.$t('success'),
      titleFailed: this.$t('failed'),
      modalInfoTitle: '',
      modalInfoContent: '',
      contentSuccessProduct: this.$t('accountPage.request_product_success')
    };
  },
  setup() {
    const router = useRouter();
    // modal info
    const isOpenModalInfoRef = ref(false);
    const setOpenModalInfo = (state) => (isOpenModalInfoRef.value = state);

    // loading
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    return {
      router,
      // modal info
      isOpenModalInfoRef,
      setOpenModalInfo,
      // loading
      isOpenLoadingref,
      setOpenLoading
    };
  },
  watch: {
    $route() {
      this.key = this.key + 1;
    }
  },
  inject: ['$storage'],
  computed: {
    ...mapGetters(['status', 'error'])
  },
  async mounted() {
    this.user = await this.$storage.getUser();
    this.selectedCompany = await this.$storage.getSelectedCompany();
  },
  methods: {
    ...mapActions([ACTIONS.REQUEST_NEW_PRODUCT]),
    async requestProduct(product) {
      this.setOpenLoading(true);

      const params = {
        customerName: this.selectedCompany.name,
        customerEmail: this.user.email,
        ...product
      };
      await this[ACTIONS.REQUEST_NEW_PRODUCT](params);
      this.setOpenLoading(false);
      // show modal info
      if (this.status) {
        this.modalInfoTitle = this.titleSuccess;
        this.modalInfoContent = this.contentSuccessProduct;
      } else {
        this.modalInfoTitle = this.titleFailed;
        this.modalInfoContent = this.error.message;
        this[ACTIONS.RESET_STATUS_ERROR]();
      }
      this.setOpenModalInfo(true);
    },
    closeModalInfo() {
      this.setOpenModalInfo(false);
      setTimeout(() => this.router.push({ path: '/b2b/account' }), 200);
    }
  }
});
</script>
