<template>
  <vee-form class="px-5" :validation-schema="productSchema">
    <!-- supplier name -->
    <ion-item :class="['fs-2 custom-item', 'item-' + getColorValidate('supplierName', errors.supplierName)]">
      <ion-label :color="getColorValidate('supplierName', errors.supplierName)" position="floating">
        {{ $t('accountPage.supplier_name') }}
      </ion-label>
      <ion-input
        v-model="product.supplierName"
        :value="product.supplierName"
        name="supplierName"
        placeholder=""
        type="text"
        @ionInput="onInputChange"
      ></ion-input>
    </ion-item>
    <ion-text v-if="errors.supplierName" color="danger">
      <div class="pr-1 py-1 font-size-12">{{ $t(`${errors.supplierName}`) }}</div>
    </ion-text>

    <!-- product sku name -->
    <ion-item
      :class="['fs-2 custom-item', 'item-' + getColorValidate('productSkuName', errors.productSkuName)]"
    >
      <ion-label :color="getColorValidate('productSkuName', errors.productSkuName)" position="floating">
        {{ $t('accountPage.product_sku_name') }}
      </ion-label>
      <ion-input
        v-model="product.productSkuName"
        :value="product.productSkuName"
        name="productSkuName"
        placeholder=""
        type="text"
        @ionInput="onInputChange"
      ></ion-input>
    </ion-item>
    <ion-text v-if="errors.productSkuName" color="danger">
      <div class="pr-1 py-1 font-size-12">{{ $t(`${errors.productSkuName}`) }}</div>
    </ion-text>

    <!-- price -->
    <ion-item class="fs-2 custom-item">
      <ion-label position="floating" color="medium">{{ $t('price') }}</ion-label>
      <ion-input
        v-model="product.price"
        :value="product.price"
        name="price"
        placeholder=""
        type="number"
        @ionInput="onInputChange"
      ></ion-input>
    </ion-item>

    <!-- remarks -->
    <ion-item class="fs-2 custom-item">
      <ion-label position="floating" color="medium">{{ $t('accountPage.remarks') }}</ion-label>
      <ion-input
        v-model="product.remarks"
        :value="product.remarks"
        name="remarks"
        placeholder=""
        type="text"
        @ionInput="onInputChange"
      ></ion-input>
    </ion-item>
    <ion-button class="btn-save mt-5" expand="full" @click="requestProduct">{{ $t('send') }}</ion-button>
  </vee-form>
</template>

<script>
import { defineComponent } from 'vue';
import { useForm, useField, Form as VeeForm } from 'vee-validate';

export default defineComponent({
  name: 'RequestProduct',
  components: {
    VeeForm
    // User Defined component
  },
  data() {
    return {
      firstChange: {
        supplierName: false,
        productSkuName: false
      }
    };
  },
  setup() {
    // Define a validation schema
    const productSchema = {
      supplierName: (val) => (!val ? 'accountPage.cannot_be_empty' : true),
      productSkuName: (val) => (!val ? 'accountPage.cannot_be_empty' : true)
    };

    // Create a form context with the validation schema
    const { errors, validate, values: product } = useForm({
      validationSchema: productSchema,
      initialValues: {
        supplierName: '',
        productSkuName: '',
        price: '',
        remarks: ''
      }
    });

    const { value: supplierName } = useField('supplierName');
    const { value: productSkuName } = useField('productSkuName');
    const { value: price } = useField('price');
    const { value: remarks } = useField('remarks');

    return {
      productSchema,
      errors,
      validate,
      product,

      // form data
      supplierName,
      productSkuName,
      price,
      remarks
    };
  },
  mounted() {
    this.initForm();
  },
  watch: {
    $router() {
      this.initForm();
    }
  },
  emits: ['request-product'],
  methods: {
    initForm() {
      // init values
      this.supplierName = '';
      this.productSkuName = '';
      this.price = '';
      this.remarks = '';
    },
    getColorValidate(name, errMsg) {
      if (this.firstChange[name]) {
        return errMsg ? 'danger' : 'primary';
      }
      return 'medium';
    },
    onInputChange(event) {
      this.firstChange[event.target.name] = true;
    },
    async requestProduct() {
      // set first change is true
      for (const key in this.firstChange) {
        this.firstChange[key] = true;
      }
      // validate form
      const { valid } = await this.validate();
      if (valid) {
        this.product.price = Number(this.product.price);
        this.$emit('request-product', this.product);
      }
    }
  }
});
</script>
