<template>
  <ion-header class="d-flex align-center custom-header">
    <ion-toolbar class="px-3 custom-toolbar">
      <ion-back-button
        slot="start"
        size="small"
        :icon="arrowBackOutline"
        :defaultHref="backHref"
        text=""
        color="dark"
        class="fs-1 custom-back-button"
      >
      </ion-back-button>
      <ion-text class="ml-3 fs-3 fw-bold">{{ title }}</ion-text>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { IonBackButton, IonHeader, IonToolbar, IonText } from '@ionic/vue';
import { defineComponent } from 'vue';
import { arrowBackOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'SimpleHeader',
  components: {
    IonBackButton,
    IonHeader,
    IonToolbar,
    // IonButtons,
    IonText
  },
  props: ['backHref', 'title'],
  setup() {
    return {
      arrowBackOutline
    };
  }
});
</script>

<style scoped>
.custom-toolbar {
  --border-width: 0px !important;
}
.custom-header {
  height: 56px;
}
.custom-header.header-md::after {
  background-image: none;
}
.custom-back-button.back-button-has-icon-only {
  min-width: 0px;
  height: 32px;
  width: 32px;
}
.custom-back-button::part(native) {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}
</style>
